import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { CxSearchBarComponent } from '@bbraun/cortex/search-bar';

@Component({
  selector: 'hpm-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrl: './search-bar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchBarComponent implements OnDestroy, OnChanges {
  @Output()
  searchValue: EventEmitter<string[]> = new EventEmitter<string[]>();
  selectedValue: Subject<string> = new Subject<string>();
  onDestroy$ = new Subject<void>();
  @Input()
  autocompleteOptions: string[] = [];
  @Input() placeholder = '';
  filteredAutocompleteOptions: string[] = [];
  @ViewChild('searchBar') searchBar!: CxSearchBarComponent;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['autocompleteOptions']) {
      this.filteredAutocompleteOptions = this.autocompleteOptions;
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  searchAll(searchValue: string): void {
    const searchValues = searchValue.split(' ');
    this.filterAutocompleteOptions(searchValues);
    this.searchValue.emit(searchValues);
  }

  onAutocompleteSelected(viewValue: string): void {
    this.selectedValue.next(viewValue);
  }

  private filterAutocompleteOptions(searchValue: string[]): void {
    this.filteredAutocompleteOptions = [...this.autocompleteOptions].filter(
      (value) => this.autocompleteValueInSearchoptions(value, searchValue),
    );
  }

  private autocompleteValueInSearchoptions(
    value: string,
    searchValue: string[],
  ): boolean {
    return searchValue.every((v) =>
      value?.toLowerCase().match(v?.toLowerCase()),
    );
  }

  closeAutocomplete(): void {
    this.searchBar.autocompleteTrigger.closePanel();
    this.searchBar.blur();
  }
}
